/**
 * @see https://stackoverflow.com/questions/43557755/how-to-call-ajax-in-wordpress
 */
 (function() {


    function handle_response()
    {
        const filter_name = get_filter_name_from_URL(this.responseURL);
        if (this.status >= 200 && this.status < 400) {
            const $results_container = get_results_container(filter_name);
            var parser = new DOMParser();
	        var doc = parser.parseFromString(this.response, 'text/html');
            var results = doc.querySelector('.archive-listing--use-filters');
            set_loaded_state(filter_name);
            if($results_container){
                $results_container.innerHTML = results.innerHTML;
            }
            window.history.pushState({}, "", this.responseURL);
        } else {
            handle_error.call(this);
        }
    }

    function handle_error()
    {
        const filter_name = get_filter_name_from_URL(this.responseURL);
        set_loaded_state(filter_name);
        throw new FetchResultsException(this);
    }

    function FetchResultsException(response)
    {
        this.message = "Error while fetching results from Admin-Ajax";
        this.response = response;
        this.toString = function() {
            return this.message;
        }
    }

    function get_filter_name_from_URL(URL)
    {
        const queryString = URL.substring(URL.indexOf("?"));
        const params = new URLSearchParams(queryString);
        return params.get('filter');
    }

    function set_loading_state(filter_name)
    {
        const $results_container = get_results_container(filter_name);
        if($results_container){
            $results_container.classList.remove("archive-listing--loaded");
            $results_container.classList.add("archive-listing--loading");
        }
        const $notification = document.querySelector('.archive-filtres__notification');
        if($notification){
            $notification.innerHTML = archive_filtres_message.loading;
        }
    }

    function set_loaded_state(filter_name)
    {
        const $results_container = get_results_container(filter_name);
        if($results_container){
            $results_container.classList.add("archive-listing--loaded");
            $results_container.classList.remove("archive-listing--loading");
        }
        const $notification = document.querySelector('.archive-filtres__notification');
        if($notification){
            $notification.innerHTML = archive_filtres_message.loaded;
        }
    }

    function get_results_container(filter_name)
    {
        //return document.getElementById(filter_name+'-results');
        return document.querySelector('.archive-listing--use-filters');
    }

    function fetch_results(e)
    {
        e.preventDefault();
        const $form = e.currentTarget;
        const filter_name = $form.dataset.archiveFiltres;
        let formdata = new FormData($form);

        set_loading_state(filter_name);

        //wp.ajax uses jQuery
        //We will do it ourselves
        let request = new XMLHttpRequest();

        //formdata.append('action', 'archive_filters');
        formdata.append('filter', filter_name);
        formdata.append('nocache', +new Date);

        let params = new URLSearchParams(formdata);
        const url = $form.action+'?'+params.toString()+'#'+$form.id;

        request.open('GET', url, true);
        request.onload = handle_response;
        request.onerror = handle_error;
        request.send();
    }

    /**
     * Auto init all elements with a data-archive-filtres attribute
     */
    function init_archives_filters() {
        const filters_forms = document.querySelectorAll('[data-archive-filtres]');
        
        for (let i = 0; i < filters_forms.length; ++i) {
            let $element = filters_forms[i];

            if ($element.classList.contains("js-archive-filtres-active")) {
                continue;
            }

            $element.classList.add("js-archive-filtres-active");
            //$element.addEventListener("change", fetch_results);
            $element.addEventListener("submit", fetch_results);
        }
    }

    if (typeof window.activis == "undefined") {
        window.activis = {};
    }
    window.activis.init_archives_filters = init_archives_filters;
    
    init_archives_filters();

    let $resetButton = document.getElementsByClassName('wp-block-archive-filtres__reset');
    Array.from($resetButton).forEach(($el)=>{
        $el.addEventListener('click',init_reset_filters);
        return false;
    })
    function init_reset_filters(e) {
        e.preventDefault();
        set_loading_state();
        const $form = e.currentTarget.closest('form');
        const url = $form.action;

        let request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.onload = handle_response;
        request.onerror = handle_error;
        request.send();
    }

})();
